.custom-textarea {
    width: 100%;
    border-radius: 3px;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 375px;
    padding: 15px 0;
    margin-bottom: 40px;
    .error-text {
        color: red;
        margin: 10px 0 0 4px;
        height: 20px;
    }
    &__area {
        width: 97%;
        height: calc(100%);
        padding: 15px;
        font-size: 21px;
        border-radius: 19px;
        background-color: #d9d9d9;
        border: none;
        resize: none;

        &:disabled {
            cursor: pointer;
        }
        &--white{
            background-color: #fff;
            border: 1px solid #d9d9d9;
        }
    }
}
