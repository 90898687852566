.wrapper {
    height: 100%;
    &--flex {
        display: flex;
        flex-direction: column;
    }
}
.products {
    &-head {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        font-size: 26px;
        font-weight: bold;
        &__blue {
            color: #11c4ec;
        }
    }
    &__search {
        padding: 0 7px;
        margin: 12px auto;
    }
    &-hero {
        padding: 0 14px;
        overflow-y: scroll;
        height: calc(100% - 72px - 55px - 103px - 90px);
        margin-right: 6px;
        &--size {
            height: calc(100% - 72px - 55px - 103px - 55px - 125px);
        }
    }
    &-summary {
        padding-bottom: 18px;
        &__head {
            margin-bottom: 75px;
            width: 100%;
            grid-gap: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        &__info {
            font-family: 'Inria Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            margin: 0 0 15px 0;
            color: #000000;
        }
        &__text {
            font-size: 30px;
            font-weight: bold;
            display: flex;
            gap: 20px;
        }
        &__footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
        }
    }
    &__back {
        height: 48px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 15px;
        font-size: 24px;
        font-weight: bold;
        grid-gap: 10px;
        cursor: pointer;
        &:hover {
            opacity: 0.6;
        }
    }
    &__red {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        color: #ff0000;
        max-width: 675px;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &-details {
        padding: 43px 25px;
        display: flex;
        justify-content: flex-end;
        grid-gap: 15px;
        align-items: center;
        &__chekbox {
            max-width: 285px;
            width: 100%;
        }
    }
    &-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 20px;
        margin: 30px 0;
        padding: 15px 30px 15px 0;

        &__title {
            max-width: 375px;
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 30px;
            color: #000000;
        }
        &__text-area {
            width: 100%;
            height: 163px;
            padding: 9px;
            line-height: 1.5;
            font-size: 21px;
            border-radius: 19px;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            resize: none;
        }
        &--start {
            align-items: flex-start;
        }
    }

    &-confirm {
        font-family: 'Inria Sans';
        font-style: normal;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        &__text {
            margin-bottom: 36px;
        }
        &__footer {
            padding: 30px;
            font-size: 32px;
            .link {
                color: #4f4d4d;
                text-decoration: none;
                margin-right: 10px;
                padding-right: 10px;
                line-height: 0.5;
                border-right: 2px solid #4f4d4d;
            }
        }
        &__main {
            flex-grow: 1;
            padding-top: 110px;
            font-size: 30px;
            text-align: center;
            font-weight: bold;
            max-width: 865px;
            width: 100%;
            color: #000000;
        }
    }
}
.date {
    display: flex;
    align-items: center;
    &__wrap {
        display: flex;
        align-items: center;
        width: 78%;
        justify-content: space-around;
        font-size: 21px;
    }
    &__asap {
        display: flex;
        align-items: center;
    }
    &__text {
        margin: 0 20px 0 0;
    }
    &__checkbox {
        box-shadow: 0 0 0 1px #000000;
        background-color: #ffffff;
        border-radius: 3px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
.date-picker {
    background: gray;
    width: 100%;
    padding: 9px;
    line-height: 1.5;
    font-size: 21px;
    border-radius: 19px;
    background-color: #d9d9d9;
    border: none;
}
.product__blue {
    color: #11c4ec;
}
